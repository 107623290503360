import {
	createContext,
	createEffect,
	createMemo,
	For,
	on,
	onCleanup,
	Show,
	useContext,
	type Component,
	type ComponentProps,
	type Accessor,
} from "solid-js"
import { createMutable } from "solid-js/store"

import { CancelSignal, drop, type ComponentLike, type ComposableComponentProps, recompose, throttle } from "#/lib/mod"
import { BasicInput, fuzzySort, Spinner, Tag } from "#/components/mod"

type DropdownInputProps = {
	disabled?: boolean
	tabindex?: number
	classList: Record<string, boolean>

	ref(ref: HTMLInputElement): void

	onInput?(e: InputEvent): void
	onClick?(e: MouseEvent): void
}

type Ctx<TItem, TMapped extends string> = ReturnType<typeof Dropdown<TItem, TMapped>>["ctx"]

let DropdownContext = createContext<Ctx<any, any>>()
let useDropdownContext = <TItem, TMapped extends string = string>() => useContext(DropdownContext) as Ctx<TItem, TMapped>

type DropdownProps<I, M extends string> = {
	// user-provided reactive values
	options?: I[]
	selected?: I | I[]
	error?: string
	disabled?: boolean
	placeholder?: string

	// settings
	throttle_interval_ms?: number

	// user hooks
	display?(item: I): M | null
	apply?(item: I, ctx: Ctx<I, M>): void
	search?(value: string, signal?: CancelSignal): I[] | Promise<I[]>
	compare?(a: I, b: I): boolean
	retainSuggestions?(item: I, ctx: Ctx<I, M>): boolean
	afterSelect?(item: I, text: string, ctx: Ctx<I, M>, reason?: "cancel"): void | false
	afterInput?(e: InputEvent): void



	// components
	Root?: ComponentLike<"div">
	Input?: Component<DropdownInputProps>
	Suggestions?: ComponentLike<typeof Dropdown.Suggestions<I>>
	RightIcon?: ComponentLike<typeof Dropdown.RightIcon>
	Error?: ComponentLike<typeof BasicInput.Error>
}

export function Dropdown<I, M extends string>(props: DropdownProps<I, M>) {
	let {
		Root = p => <dropdown {...p} />,
		Input = BasicInput,
		Suggestions = Dropdown.Suggestions,
		RightIcon = Dropdown.RightIcon,
		Error = BasicInput.Error,
	} = props

	let hooks = function() {
		let defaults: typeof props = {
			search(value: string) {
				return fuzzySort(value, ctx.options).map(x => x.item)
			},
			compare(a, b) {
				return a == b
			},
			display(item: I) {
				return item?.toString() as M
			},
			apply(item: I) {
				let mapped = ctx.hooks.display(item)
				ctx.input.value = typeof mapped === "string" ? mapped : null // ?? null to avoid `undefined` string in input
			},
			retainSuggestions(item, ctx) {
				return !ctx.getSelectedAsArray().some(s => ctx.hooks.compare(s, item))
			},
		}
		let { uncomposed: user_config } = recompose(props,
			"search", "compare", "display", "apply", "retainSuggestions", "afterInput", "afterSelect"
		)

		return { ...defaults, ...user_config }
	}()

	let ctx = createMutable({
		hooks,

		get options() { return props.options ?? [] },
		get selected() { return props.selected },
		get error() { return props.error },
		get disabled() { return props.disabled },
		get placeholder() { return props.placeholder },

		input: null as HTMLInputElement,

		opened: false,
		searching: false,
		hover: null as I,
		cached_options: [] as I[],

		getSelectedAsArray: null as Accessor<I[]>,
		getFilteredSuggestions: null as Accessor<I[]>,

		isInputFocused,
		requestOpen,
		close,
		preAfterSelect,
	})

	ctx.hover = ctx.options[0]
	ctx.cached_options = [...ctx.options]

	ctx.getSelectedAsArray = createMemo(() => {
		let selected: I[]
		if (ctx.selected == null)
			selected = []
		else
			selected = Array.isArray(ctx.selected) ? ctx.selected : [ctx.selected]

		// Apply newly selected option(s)
		if (ctx.input) {
			selected.forEach(item => ctx.hooks.apply(item, ctx))
		}
		return selected
	})

	ctx.getFilteredSuggestions = createMemo(() => ctx.cached_options.filter(item => ctx.hooks.retainSuggestions(item, ctx)))

	// Whenever input option changes, we're changing displayed options and hover.
	createEffect(on(() => [...ctx.options], () => {
		if (!ctx.options.length)
			return

		ctx.cached_options = [...ctx.options]
		ctx.hover = ctx.options[0]
	}))

	function isInputFocused() {
		return document.activeElement === ctx.input
	}

	function preAfterSelect(opt: I) {
		ctx.hooks.apply(opt, ctx)
		ctx.close()
		ctx.hooks.afterSelect?.(opt, ctx.input.value, ctx)
	}

	async function requestOpen() {
		if (ctx.opened) return

		if (!isInputFocused())
			ctx.input.focus()

		if (ctx.getFilteredSuggestions().isEmpty()) {
			await search(ctx.input.value)
		}

		// Если переключились, то не открываем
		if (!isInputFocused())
			return

		ctx.opened = true
	}

	function close() {
		ctx.opened = false
		ctx.hover = null
	}

	let search_signal: CancelSignal
	let search = throttle(async (str: string) => {
		if (search_signal)
			search_signal.cancel()

		ctx.searching = true

		let signal = CancelSignal()
		search_signal = signal

		let result = await async function() {
			if (!ctx.hooks.search) {
				return [...ctx.options]
			}
			let result = await ctx.hooks.search(str, signal)
			if (result.isEmpty()) {
				return [...ctx.options]
			}
			return result
		}()

		if (signal.cancelled) {
			return
		}

		ctx.cached_options = result
		ctx.searching = false
	}, props.throttle_interval_ms ?? 220)

	function findOptionFromInputText() {
		let target = ctx.input.value.trim()
		return ctx.cached_options.find(sug => ctx.hooks.display(sug) === target)
	}

	function onCancel(e: FocusEvent & { currentTarget: HTMLDivElement, target: Element, relatedTarget: any }) {
		if (!e.currentTarget.contains(e.relatedTarget)) {
			// TODO make better logic here
			if (ctx.opened)
				ctx.close()

			let wish = findOptionFromInputText()
			ctx.hooks.afterSelect?.(wish, ctx.input.value, ctx, "cancel")
		}
	}

	function onKeyDown(ev: KeyboardEvent) {
		// if (!s.opened) return

		// in mobile browser key instead of code
		let key = ev.code.length ? ev.code : ev.key

		if (["ArrowDown", "ArrowUp", "Enter"].includes(key)) {
			ev.preventDefault()
			ev.stopPropagation()
		}
		if (key === "Tab") {
			if (ctx.opened) {
				ev.preventDefault()
				ev.stopPropagation()
			}
			else return
		}

		let index = ctx.getFilteredSuggestions().indexOf(ctx.hover)

		switch (key) {
			case "Enter": {
				if (!ctx.hover && ctx.input.value.length === 0)
					return

				let opt = ctx.getFilteredSuggestions()[index] ?? findOptionFromInputText()
				// inputRef.value = curOpt.text
				// requestSearch(s.hover)
				let result = ctx.hooks.afterSelect?.(opt, ctx.input.value, ctx)
				if (result !== false) {
					ctx.close()
					ctx.hooks.apply(opt, ctx)
				}
				break
			}
			case "Tab":
				if (!ctx.opened) ctx.requestOpen()
			case "ArrowUp":
			case "ArrowDown": {
				if (index < 0) {
					ctx.hover = ctx.getFilteredSuggestions()[0]
					return
				}

				if (key === "ArrowDown" || key === "Tab" && !ev.shiftKey) {
					index++
				}
				if (key === "ArrowUp" || key === "Tab" && ev.shiftKey) {
					index--
				}

				if (index < 0) {
					index = ctx.getFilteredSuggestions().length - 1
				}
				if (index > ctx.getFilteredSuggestions().length - 1) {
					index = 0
				}

				let opt = ctx.getFilteredSuggestions()[index]

				ctx.hover = opt
				ctx.hooks.apply(opt, ctx)
				// inputRef.value = opt.text

				break;
			}
			case "Escape":
				ctx.close()
				ctx.input.blur()
				break
		}
	}

	function onInput(ev: InputEvent & { currentTarget: HTMLInputElement; target: Element }) {
		ctx.opened = true
		search(ev.currentTarget.value)
		// Hover first suggestion
		let first_cached_option = ctx.cached_options[0]
		if (first_cached_option && ctx.hooks.compare(first_cached_option, findOptionFromInputText())) {
			ctx.hover = first_cached_option
		}
		ctx.hooks.afterInput?.(ev)
	}

	return Object.assign(
		<DropdownContext.Provider value={ctx}>
			<Root
				tabindex={0} // https://stackoverflow.com/a/42764495/8086153
				onFocusOut={onCancel}
				onKeyDown={onKeyDown}
				classList={{
					":c: focus-within:(b-b-white)": true,
					":c: dark:(c-white b-b-gray-700/60) light:(bg-gray-000 c-black-999 b-b-gray-200)": true,
					":c: flex flex-col relative contain-none rounded-2px b-unset rounded-t-2px b-b-(solid 2px offset-0)": true,
					":c: uno-layer-v1:b-b-red-500": ctx.error != null,
				}}
			>
				<Input
					ref={ref => ctx.input = ref}
					onClick={ctx.requestOpen}
					disabled={ctx.disabled}
					onInput={onInput}
					tabindex={-1}
					classList={{ ":c: uno-layer-v3:(pr-8 border-none)": true }}
				/>
				<RightIcon />
				<Show when={ctx.opened}>
					<Suggestions />
				</Show>
			</Root>
			<Show when={ctx.error}>
				<Error error={ctx.error} />
			</Show>
		</DropdownContext.Provider>,
		{ ctx },
	)
}

Dropdown.RightIcon = function(props: ComponentProps<typeof BasicInput.IconW>) {
	let ctx = useDropdownContext()

	function onClick() {
		if (ctx.disabled) {
			return
		}
		if (ctx.searching) {
			return
		}
		if (ctx.opened) {
			ctx.close()
			return
		}
		ctx.requestOpen()
	}

	return (
		<BasicInput.IconW
			{...props}
			right
			iconc={ctx.searching ? undefined : !ctx.opened ? "i-hero:chevron-down ptr" : "i-hero:chevron-up ptr"}
			Icon={p => (
				<Show
					when={ctx.searching}
					children={
						<Spinner {...p}
							classList={{
								...p.classList,
								":c: size-6 dark:c-gray-400 light:c-gray-600": true,
							}}
						/>
					}
					fallback={<i {...p}
						classList={{
							...p.classList,
							":c: size-6 dark:c-gray-400": true,
						}}
					/>}
				/>
			)}
			onClick={onClick}
		// disabled={ctx.disabled}
		/>
	)
}

type DropdownSuggestionProps<TItem> = {
	item: TItem
	index: number
} & ComposableComponentProps<"div">

Dropdown.Suggestion = function <TItem>(props: DropdownSuggestionProps<TItem>) {
	let other = drop(props, "item", "index", "classList")

	let ctx = useDropdownContext<TItem>()

	return (
		<div
			onPointerDown={e => e.preventDefault()}
			onClick={[ctx.preAfterSelect, props.item]}
			tabindex={props.index + 2}
			children={ctx.hooks.display(props.item)}
			{...other}
			classList={{
				...props.classList,
				[":c: w-full h-10 overflow-hidden text-sm font-medium text-ellipsis ws-nowrap rounded-5px p-inline-3 ptr"]: true,
				[":c: leading-[2.8] dark:hover:bg-gray-700/30 light:hover:bg-gray-100/30"]: true,
				[":c: uno-layer-v2:(dark:bg-gray-700/50 light:bg-gray-100)"]: ctx.hover === props.item,
			}}
		/>
	)
}

type DropdownSuggestionsProps<TItem> = {
	Suggestion?: ComponentLike<typeof Dropdown.Suggestion<TItem>>
	class?: string
} & ComposableComponentProps<"div">

Dropdown.Suggestions = function <TItem>(props: DropdownSuggestionsProps<TItem>) {
	let { Suggestion = Dropdown.Suggestion } = props

	let other = drop(props, "class", "classList", "Suggestion")

	let ctx = useDropdownContext<TItem>()

	let ref: HTMLDivElement
	let children = new Map<TItem, HTMLDivElement>()

	createEffect(on(() => ctx.hover, (v) => {
		let el = children.get(v)
		if (!el) return

		let { height: container_height } = ref.getBoundingClientRect()

		if (el.offsetTop + el.clientHeight > ref.scrollTop + container_height) {
			let { height: el_height } = el.getBoundingClientRect()
			ref.scrollTo({ top: el.offsetTop - container_height + el_height * 3, behavior: "smooth" })
		}
		else if (el.offsetTop < ref.scrollTop) {
			let { height: el_height } = el.getBoundingClientRect()
			ref.scrollTo({ top: el.offsetTop - el_height * 3, behavior: "smooth" })
		}
	}))

	return (
		<Show when={!ctx.getFilteredSuggestions().isEmpty()}>
			<suggestions
				{...other}
				ref={r => ref = r}
				classList={{
					...props.classList,
					[props.class]: !!props.class,
					":c: [&>*+*]:mt-4px": true,
					":c: overflow-(x-hidden y-auto) scrollbar-width-thin overscroll-contain": true,
					":c: absolute top-[calc(100%+8px)] w-full max-h-67 rounded-2 text-4 p-1 z-1000": true,
					":c: b-(2px solid) light:(bg-gray-000 b-gray-100) dark:(bg-gray-900/80 b-gray-800) backdrop-blur-12px": true,
				}}
			>
				<For each={ctx.getFilteredSuggestions()}
					children={(item, i) =>
						<Suggestion
							ref={r => {
								children.set(item, r)
								onCleanup(() => children.delete(item))
							}}
							item={item}
							index={i()}
						/>
					}
				/>
			</suggestions>
		</Show>
	)
}

type DropdownMultiSelectInputProps<TItem> = DropdownInputProps & {
	onRemove?(item: TItem, ctx: Ctx<TItem, any>): any
	Input?: ComponentLike<"input">
}

Dropdown.MultiSelectInput = function <TItem>(props: DropdownMultiSelectInputProps<TItem>) {
	let ctx = useDropdownContext<TItem>()

	let { Input = BasicInput } = props
	return (
		<field
			classList={{
				":c: uno-layer-v2:[&>input]:h-auto": true,
				":c: flex flex-wrap items-stretch gap-1 w-full h-auto overflow-hidden text-ellipsis p-1 min-h-10 relative b-none rounded-inherit": true,
				":c: light:bg-gray-000 dark:bg-gray-950": !props.disabled,
				":c: dark:bg-gray-800/50 light:bg-gray-000/50": props.disabled,
			}}
			onClick={(e) => {
				if (!ctx.isInputFocused())
					ctx.input.focus()

				props.onClick?.(e)
			}}
		>
			<For
				each={ctx.getSelectedAsArray()}
				children={(opt, i) => (
					<Tag
						class=":c: uno-layer-v1:(flex items-center gap-1 p-block-2 p-inline-3 text-3 c-blue-500 font-500 dark:bg-white rounded-18px h-8)"
						Text={() => <span class="shrink-0" children={ctx.hooks.display(opt)} />}
						Icon={() => <i class=":c: i-hero:x-mark-20-solid size-5 ptr" onClick={() => props.onRemove?.(opt, ctx)} />}
					/>
				)}
			/>
			<Input
				ref={props.ref}
				tabindex={props.tabindex}
				onInput={props.onInput}
				type="text"
				disabled={ctx.disabled}
				placeholder={ctx.placeholder}
				classList={{
					...props.classList,
					":c: uno-layer-v2:pl0": !ctx.getSelectedAsArray().isEmpty(),
					":c: uno-layer-v2:(b-none min-w-30px text-sm flex-1)": true
				}}
			/>
		</field>
	)
}
