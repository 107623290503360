import { createEffect, createMemo, Match, Show, Switch, type ComponentProps } from "solid-js"

import {
	useCache, signal, recompose, cl,
	type ComponentLike, type ComposableComponentProps,
	stringToColor,
	type R,
	drop
} from "#/lib/mod"
import { getPictureUrl } from "#/lib/media/mod"

import { IMG_REMOVE_BORDER_SRC } from "./picture"


function NamedAvatar(props: { name: string } & ComposableComponentProps<"div">) {
	let other = drop(props, "name", "classList", "style")

	let [first_name, surname] = props.name.split(" ")

	let bg = signal(null)

	Promise
		.all([first_name, surname].map(n => stringToColor(n)))
		.then(([c1, c2]) => bg(`linear-gradient(150deg,${c1},${c2})`))

	return (
		<div {...other}
			classList={{
				":c: flex-center font-bold c-white select-none": true,
				...props.classList
			}}
			style={{
				"background": bg(),
				"container-type": "inline-size",
				...props.style
			}}
		>
			<span class="text-50cqw" children={[first_name[0], surname[0]]} />
		</div>
	)
}

type UserAvatarProps = {
	user: Partial<Pick<R.User, "avatar_key" | "first_name" | "last_name" | "id">>
	Wrapper?: ComponentLike<"div">
	Img?: ComponentLike<"img">
} & ComposableComponentProps<"div"> & { class?: string }

export function UserAvatar(props: UserAvatarProps) {
	let other = drop(props, "user", "Wrapper", "Img", "class", "classList")

	let {
		Wrapper = p => <div {...p} />,
		Img = p => <img {...p} />
	} = props

	let cache = useCache()

	let error = signal(false)

	let getOnlineStatus = createMemo(() => cache.resolve("presense_list", props.user?.id)?.status)

	createEffect(() => {
		props.user
		props.user?.first_name
		props.user?.last_name
		props.user?.avatar_key
		error(false)
	})

	let ImgWrapped = (p: ComponentProps<"img">) =>
		<Img {...p}
			classList={{
				":c: h-full w-full object-cover rounded-inherit": true,
				...p.classList,
			}}
			onError={error.bind(null, true)}
		/>

	return (
		<Wrapper
			{...other}
			classList={{
				":c: relative rounded aspect-ratio-square max-w-inherit max-h-inherit select-none": true,
				"dark:bg-gray-800 light:bg-gray-200": true,
				[props.class]: !!props.class,
				...props.classList
			}}
			style={{
				"container-type": "inline-size",
				...props.style
			}}
		>
			<Switch>
				<Match when={!props.user || !props.user.avatar_key}>
					<i classList={{ "i-hero:camera abs-centered size-50cqw": true }} />
				</Match>
				<Match when={props.user.avatar_key}>
					<ImgWrapped src={getPictureUrl(props.user.avatar_key)} />
				</Match>
			</Switch>
			<Show when={getOnlineStatus()}>
				<div class=":c: absolute rounded h-25% w-25% right-3% bottom-3% light:bg-white dark:bg-gray-900">
					<div
						classList={{
							":c: h-75% w-75% rounded abs-centered": true,
							[function() {
								switch (getOnlineStatus()) {
									case 1: return "bg-green"
									case 2: return "i-hero:moon-solid bg-amber"
								}
							}()]: true
						}}
					/>
				</div>
			</Show>
		</Wrapper>
	)
}
