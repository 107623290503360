export * from "./navigation"
export * from "./in-page-view"
export * from "./routes"


import { createComputed, untrack } from "solid-js"

import { useRouter, type View } from "./navigation"
import { unwrap } from "solid-js/store"

export function onPageWasNavigated(fn?: (view: View, prev_vie: View | null) => void, exit?: () => void) {
	let router = useRouter()
	let prev_view: View = null
	createComputed(() => {
		if (router.top?.pathname !== router.view.pathname) {
			exit && untrack(exit)
			return
		}
		fn && untrack(fn.bind(null, router.view, prev_view))
		prev_view = unwrap({ ...router.view })
	})
}
