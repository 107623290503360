import { batch, children, createComputed, createEffect, mergeProps, on, onCleanup, untrack, type ComponentProps } from "solid-js"
import { createMutable } from "solid-js/store"

import { usePage } from "../common/page/page.conext"
import { doNextFrame, useRouter, type ComponentLike } from "./mod"
import { onPageWasNavigated } from "./navigation/mod"


export let CommonModalWrapper = (props: ComponentProps<"div">) => (
	<div {...props} classList={{ "absolute z102 min-w-max will-change-transform": true, ...props.classList }} />
)

function getSafePosition(element: HTMLElement, wish_left: string, wish_top: string, x_root = document.body, v_root = x_root) {
	let x_root_bounds = x_root.getBoundingClientRect()
	let modal_bounds = element.getBoundingClientRect()

	// page right end - width of modal - some shit
	// root_bounds.right - root_bounds.x != root_bounds.with, probably there could be additional spacing
	let left = `clamp(0px, ${wish_left}, calc(${x_root_bounds.right - x_root_bounds.x - modal_bounds.width}px - env(safe-area-inset-right)))`
	// let top = `calc(${wish_top} - ${v_root.offsetTop - v_root.scrollTop}px)`
	let top = `clamp(${v_root.offsetTop + v_root.scrollTop}px, calc(${wish_top} - ${v_root.offsetTop - v_root.scrollTop}px), calc(${v_root.scrollTop + v_root.offsetHeight - modal_bounds.height}px - env(safe-area-inset-bottom)))`
	return { left, top }
}


/*
	I wasn't able to make <Transition> to not create persistent dom node to mount in
	There is a way to have singletone modal root and mount into it, but what if we want multiple roots?
	Maybe there is also a way with Portal, but Portal itself creates unnescessary wrapper <div>
	So it requires to reimplemented part of Transition logic here, unfortunatelly.
	Maybe later I will able to generalize it to replace <Transition> with it too.
*/

// FIXME: remake to single instance per popup
// This function called a lot of times!
export function createOverlay(x_root?: HTMLElement, v_root: HTMLElement = document.body) {
	let state = createMutable({
		top: null as string,
		left: null as string,
		show: false,
	})

	let overlay: HTMLElement, element: HTMLElement
	let smol_class = ":c: scale-85 opacity-65".split(" ")
	let anim_class = ":c: transition-(60 ease-linear property-[transform,opacity])".split(" ")

	function set(opts: Partial<typeof state>) {
		batch(() => Object.assign(state, opts))
	}

	function showInPlace(e: Event & { currentTarget: HTMLElement }) {
		let { left, top } = e.currentTarget.getBoundingClientRect()
		set({ show: true, left: left + "px", top: top + 25 + "px" })
	}

	function showAtCursor(e: MouseEvent & { currentTarget: HTMLElement }) {
		set({ show: true, left: e.clientX + "px", top: e.clientY + "px" })
	}

	type ModalDisplayOptions = {
		Content: ComponentLike<"div">
		Background?: ComponentLike<"div">

		cancellable?: boolean
		safe_position?: boolean
		centered?: boolean
	}
	function VirtualRoot(props: ModalDisplayOptions) {
		props = mergeProps({
			Background: p => <div {...p} />,

			cancellable: true,
			safe_position: true,
			centered: false,
		}, props)

		let page = usePage()

		onPageWasNavigated(null, () => {
			state.show = false
			unmount()
		})

		createComputed(on(() => state.show, (show) => {
			if (show) {
				x_root ??= page.modal_root
				v_root ??= document.documentElement

				// @ts-ignore
				element = children(props.Content)(ctx) as HTMLElement

				overlay = (
					<props.Background
						classList={{
							":c: dark:bg-gray-800/10 light:bg-gray-000/10": true,
							":c: fixed inset-0 z-101 backdrop-blur-3px ": true,
							// ":c: before-(content-empty absolute w-full h-full backdrop-blur-3px)": true,
							":c: transition-(60 ease-linear property-[background-color,backdrop-filter])": true,
						}}
						// @ts-ignore
						on:click={(e) => {
							if (e.currentTarget !== e.target) return // prevent firing when <Content> has non-delegated events
							if (props.cancellable) {
								state.show = false
							}
						}}
					/>
				) as HTMLElement

				element.style.visibility = "hidden"

				document.body.appendChild(overlay)
				x_root.appendChild(element)

				if (props.centered) {
					let modal_bounds = element.getBoundingClientRect()

					element.style.left = `calc(50% - ${modal_bounds.width / 2}px)`
					element.style.top = `calc(50vh - ${modal_bounds.height / 2 - v_root.offsetTop - v_root.scrollTop}px)`
				}
				else if (props.safe_position) {
					Object.assign(element.style, getSafePosition(element, state.left, state.top, x_root, v_root))
				}
				element.classList.add(...smol_class)

				doNextFrame(() => {
					if (!overlay) return

					element.addEventListener("transitionend", () => element.classList.remove(...anim_class), {
						once: true,
					})

					element.classList.add(...anim_class)
					element.classList.remove(...smol_class)
					element.style.visibility = null
				})

				function onKeyDown({ key }: KeyboardEvent) {
					if (key === "Escape") {
						document.removeEventListener("keydown", onKeyDown)
						state.show = false
					}
				}
				document.addEventListener("keydown", onKeyDown)
			}
			else if (overlay) {
				element.classList.add(...anim_class)

				doNextFrame(() => {
					if (!overlay) return
					element.addEventListener("transitionend", unmount, { once: true })
					element.classList.add(...smol_class)
				})
			}
		}, { defer: true }))

		return null
	}

	onCleanup(unmount)

	function unmount() {
		if (overlay) {
			document.body.removeChild(overlay)
			x_root.removeChild(element)
		}
		element = null
		overlay = null
	}

	let ctx = Object.assign(state, {
		VirtualRoot,
		set,
		showInPlace,
		showAtCursor,
		overlay: () => overlay,
		element: () => element,
	})

	return ctx
}
