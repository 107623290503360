import { For } from "solid-js"

import { recompose, type ComposableComponentProps } from "#/lib/mod"

type SegmentNavProps = {
	count: number
	step: number
	onSegmentClick?(index: number): void
} & ComposableComponentProps<"div"> & { class?: string }

export function SegmentNav(props: SegmentNavProps) {
	let { composed, uncomposed } = recompose(props, "count", "step", "onSegmentClick", "class")

	return (
		<div {...composed}
			classList={{
				":c: flex gap-0.5 justify-between items-center": true,
				[uncomposed.class]: !!uncomposed.class,
				...props.classList
			}}>
			<For each={new Array(uncomposed.count).fill(null)}>
				{(_, i) => (
					<div
						class=":c: flex-1 h3 flex items-center ptr"
						onClick={() => uncomposed.onSegmentClick?.(i())}
					>
						<div
							classList={{
								":c: w-full h-0.5 rounded-12px light:bg-gray-100 dark:bg-gray-600": true,
								"uno-layer-v2:bg-white": i() === uncomposed.step,
							}}
						/>
					</div>
				)}
			</For>
		</div>
	)
}
