import type * as $t from "./serde"
import { BincodeReader, BincodeWriter } from "./bincode"

export type ApiResponse = 
	| { $: "error", $0: Error }
	| { $: "who_am_i", $0: $t.Optional<MeUser> }
	| { $: "send_otp", id: $t.u32 }
	| { $: "confirm_otp", token: $t.str }

export type ChrisitanReligion = 
	| { $: "orthodox", $0?: $t.unit }
	| { $: "catholic", $0?: $t.unit }
	| { $: "protestant", $0?: $t.unit }

export type ClientNotification = 
	| { $: "cancel", $0: $t.u32 }
	| { $: "update_presence", $0?: $t.unit }

export type ClientRequest = 
	| { $: "ping", $0?: $t.unit }
	| { $: "get_user", $0: $t.u32 }
	| { $: "update_user", $0: UpdateUser }
	| { $: "geolocations", $0: $t.str }

export type ClientResponse = 
	| { $: "pong", $0?: $t.unit }

export type Coordinates = {
	lon: $t.f64,
	lat: $t.f64,
}

export type EntityUpdateType = 
	| { $: "partial", $0?: $t.unit }
	| { $: "full", $0?: $t.unit }

export type Error = {
	message: $t.str,
}

export type Geolocation = {
	coordinates: Coordinates,
	ru: GeolocationNames,
	en: GeolocationNames,
}

export type GeolocationNames = {
	country: $t.str,
	region?: $t.Optional<$t.str>,
	city?: $t.Optional<$t.str>,
}

export type Health = {
	hiv: $t.bool,
	other: $t.Seq<$t.str>,
}

export type MeUser = {
	id: $t.u32,
	phone: $t.str,
	first_name: $t.str,
	last_name: $t.str,
	sex: Sex,
	health: Health,
	religion: Religion,
	desired_place: Geolocation,
	avatar_key: $t.str,
	zygote: $t.bool,
}

export type Religion = 
	| { $: "atheism", $0?: $t.unit }
	| { $: "agnosticism", $0?: $t.unit }
	| { $: "christianitiy", $0: ChrisitanReligion }
	| { $: "judaism", $0?: $t.unit }
	| { $: "buddhism", $0?: $t.unit }

export type RpcMessageContainer = 
	| { $: "client_request", id: $t.u32, inner: ClientRequest }
	| { $: "server_response", answer_to: $t.u32, inner: ServerResponse }
	| { $: "client_notification", inner: ClientNotification }
	| { $: "server_notification", inner: ServerNotification }
	| { $: "server_request", id: $t.u32, inner: ServerRequest }
	| { $: "client_response", answer_to: $t.u32, inner: ClientResponse }

export type ServerNotification = 
	| { $: "entity_updated", $0?: $t.unit }
	| { $: "entity_deleted", e_type: $t.u32, update_type: EntityUpdateType }

export type ServerRequest = 
	| { $: "ping", $0?: $t.unit }

export type ServerResponse = 
	| { $: "error", $0: Error }
	| { $: "pong", $0?: $t.unit }
	| { $: "user", $0: $t.Optional<User> }
	| { $: "updated_user", $0: $t.Optional<MeUser> }
	| { $: "geolocations", $0: $t.Seq<Geolocation> }

export type Sex = 
	| { $: "male", $0?: $t.unit }
	| { $: "female", $0?: $t.unit }

export type UpdateUser = {
	first_name?: $t.Optional<$t.str>,
	last_name?: $t.Optional<$t.str>,
}

export type User = {
	id: $t.u32,
	first_name: $t.str,
	last_name: $t.str,
	avatar_key: $t.str,
	zygote: $t.bool,
}

export const ApiResponse = {
	encode(value: ApiResponse, writer = new BincodeWriter()) {
		switch (value.$) {
			case "error": {
				writer.write_variant_index(0)
				Error.encode(value.$0, writer)
				break
			}
			case "who_am_i": {
				writer.write_variant_index(1)
				if (value.$0) {
					writer.write_option_tag(true)
					MeUser.encode(value.$0, writer)
				} 
				else writer.write_option_tag(false)
				              
				break
			}
			case "send_otp": {
				writer.write_variant_index(2)
				writer.write_u32(value.id)
				break
			}
			case "confirm_otp": {
				writer.write_variant_index(3)
				writer.write_string(value.token)
				break
			}
		}
		return writer.get_bytes()
	},
	decode(input: Uint8Array, reader = new BincodeReader(input)) {
		let value: ApiResponse
		switch (reader.read_variant_index()) {
			case 0: {
				value = {
					$: "error",
					$0: Error.decode(input, reader),
				} satisfies Extract<ApiResponse, { $: "error" }>
				break
			}
			case 1: {
				value = {
					$: "who_am_i",
					$0: reader.read_option_tag() ? MeUser.decode(input, reader) : null,
				} satisfies Extract<ApiResponse, { $: "who_am_i" }>
				break
			}
			case 2: {
				value = {
					$: "send_otp",
					id: reader.read_u32(),
				} satisfies Extract<ApiResponse, { $: "send_otp" }>
				break
			}
			case 3: {
				value = {
					$: "confirm_otp",
					token: reader.read_string(),
				} satisfies Extract<ApiResponse, { $: "confirm_otp" }>
				break
			}
		}

		return value
	}
}

export const ChrisitanReligion = {
	encode(value: ChrisitanReligion, writer = new BincodeWriter()) {
		switch (value.$) {
			case "orthodox": {
				writer.write_variant_index(0)
				writer.write_unit(value.$0)
				break
			}
			case "catholic": {
				writer.write_variant_index(1)
				writer.write_unit(value.$0)
				break
			}
			case "protestant": {
				writer.write_variant_index(2)
				writer.write_unit(value.$0)
				break
			}
		}
		return writer.get_bytes()
	},
	decode(input: Uint8Array, reader = new BincodeReader(input)) {
		let value: ChrisitanReligion
		switch (reader.read_variant_index()) {
			case 0: {
				value = {
					$: "orthodox",
					$0: reader.read_unit()
				} satisfies Extract<ChrisitanReligion, { $: "orthodox" }>
				break
			}
			case 1: {
				value = {
					$: "catholic",
					$0: reader.read_unit()
				} satisfies Extract<ChrisitanReligion, { $: "catholic" }>
				break
			}
			case 2: {
				value = {
					$: "protestant",
					$0: reader.read_unit()
				} satisfies Extract<ChrisitanReligion, { $: "protestant" }>
				break
			}
		}

		return value
	}
}

export const ClientNotification = {
	encode(value: ClientNotification, writer = new BincodeWriter()) {
		switch (value.$) {
			case "cancel": {
				writer.write_variant_index(0)
				writer.write_u32(value.$0)
				break
			}
			case "update_presence": {
				writer.write_variant_index(1)
				writer.write_unit(value.$0)
				break
			}
		}
		return writer.get_bytes()
	},
	decode(input: Uint8Array, reader = new BincodeReader(input)) {
		let value: ClientNotification
		switch (reader.read_variant_index()) {
			case 0: {
				value = {
					$: "cancel",
					$0: reader.read_u32(),
				} satisfies Extract<ClientNotification, { $: "cancel" }>
				break
			}
			case 1: {
				value = {
					$: "update_presence",
					$0: reader.read_unit()
				} satisfies Extract<ClientNotification, { $: "update_presence" }>
				break
			}
		}

		return value
	}
}

export const ClientRequest = {
	encode(value: ClientRequest, writer = new BincodeWriter()) {
		switch (value.$) {
			case "ping": {
				writer.write_variant_index(0)
				writer.write_unit(value.$0)
				break
			}
			case "get_user": {
				writer.write_variant_index(1)
				writer.write_u32(value.$0)
				break
			}
			case "update_user": {
				writer.write_variant_index(2)
				UpdateUser.encode(value.$0, writer)
				break
			}
			case "geolocations": {
				writer.write_variant_index(3)
				writer.write_string(value.$0)
				break
			}
		}
		return writer.get_bytes()
	},
	decode(input: Uint8Array, reader = new BincodeReader(input)) {
		let value: ClientRequest
		switch (reader.read_variant_index()) {
			case 0: {
				value = {
					$: "ping",
					$0: reader.read_unit()
				} satisfies Extract<ClientRequest, { $: "ping" }>
				break
			}
			case 1: {
				value = {
					$: "get_user",
					$0: reader.read_u32(),
				} satisfies Extract<ClientRequest, { $: "get_user" }>
				break
			}
			case 2: {
				value = {
					$: "update_user",
					$0: UpdateUser.decode(input, reader),
				} satisfies Extract<ClientRequest, { $: "update_user" }>
				break
			}
			case 3: {
				value = {
					$: "geolocations",
					$0: reader.read_string(),
				} satisfies Extract<ClientRequest, { $: "geolocations" }>
				break
			}
		}

		return value
	}
}

export const ClientResponse = {
	encode(value: ClientResponse, writer = new BincodeWriter()) {
		switch (value.$) {
			case "pong": {
				writer.write_variant_index(0)
				writer.write_unit(value.$0)
				break
			}
		}
		return writer.get_bytes()
	},
	decode(input: Uint8Array, reader = new BincodeReader(input)) {
		let value: ClientResponse
		switch (reader.read_variant_index()) {
			case 0: {
				value = {
					$: "pong",
					$0: reader.read_unit()
				} satisfies Extract<ClientResponse, { $: "pong" }>
				break
			}
		}

		return value
	}
}

export const Coordinates = {
	encode(value: Coordinates, writer = new BincodeWriter()) {
		writer.write_f64(value.lon)
		writer.write_f64(value.lat)
		return writer.get_bytes()
	},
	decode(input: Uint8Array, reader = new BincodeReader(input)) {
		const value: Coordinates = {
			lon: reader.read_f64(),
			lat: reader.read_f64(),
		}
		return value
	}
}

export const EntityUpdateType = {
	encode(value: EntityUpdateType, writer = new BincodeWriter()) {
		switch (value.$) {
			case "partial": {
				writer.write_variant_index(0)
				writer.write_unit(value.$0)
				break
			}
			case "full": {
				writer.write_variant_index(1)
				writer.write_unit(value.$0)
				break
			}
		}
		return writer.get_bytes()
	},
	decode(input: Uint8Array, reader = new BincodeReader(input)) {
		let value: EntityUpdateType
		switch (reader.read_variant_index()) {
			case 0: {
				value = {
					$: "partial",
					$0: reader.read_unit()
				} satisfies Extract<EntityUpdateType, { $: "partial" }>
				break
			}
			case 1: {
				value = {
					$: "full",
					$0: reader.read_unit()
				} satisfies Extract<EntityUpdateType, { $: "full" }>
				break
			}
		}

		return value
	}
}

export const Error = {
	encode(value: Error, writer = new BincodeWriter()) {
		writer.write_string(value.message)
		return writer.get_bytes()
	},
	decode(input: Uint8Array, reader = new BincodeReader(input)) {
		const value: Error = {
			message: reader.read_string(),
		}
		return value
	}
}

export const Geolocation = {
	encode(value: Geolocation, writer = new BincodeWriter()) {
		Coordinates.encode(value.coordinates, writer)
		GeolocationNames.encode(value.ru, writer)
		GeolocationNames.encode(value.en, writer)
		return writer.get_bytes()
	},
	decode(input: Uint8Array, reader = new BincodeReader(input)) {
		const value: Geolocation = {
			coordinates: Coordinates.decode(input, reader),
			ru: GeolocationNames.decode(input, reader),
			en: GeolocationNames.decode(input, reader),
		}
		return value
	}
}

export const GeolocationNames = {
	encode(value: GeolocationNames, writer = new BincodeWriter()) {
		writer.write_string(value.country)
		if (value.region) {
			writer.write_option_tag(true)
			writer.write_string(value.region)
		} 
		else writer.write_option_tag(false)
		              
		if (value.city) {
			writer.write_option_tag(true)
			writer.write_string(value.city)
		} 
		else writer.write_option_tag(false)
		              
		return writer.get_bytes()
	},
	decode(input: Uint8Array, reader = new BincodeReader(input)) {
		const value: GeolocationNames = {
			country: reader.read_string(),
			region: reader.read_option_tag() ? reader.read_string() : null,
			city: reader.read_option_tag() ? reader.read_string() : null,
		}
		return value
	}
}

export const Health = {
	encode(value: Health, writer = new BincodeWriter()) {
		writer.write_bool(value.hiv)
		writer.write_length(value.other.length)
		for (const item of value.other) {
			writer.write_string(item)
		}
		return writer.get_bytes()
	},
	decode(input: Uint8Array, reader = new BincodeReader(input)) {
		const value: Health = {
			hiv: reader.read_bool(),
			other: reader.read_list<$t.str>(() => reader.read_string()),
		}
		return value
	}
}

export const MeUser = {
	encode(value: MeUser, writer = new BincodeWriter()) {
		writer.write_u32(value.id)
		writer.write_string(value.phone)
		writer.write_string(value.first_name)
		writer.write_string(value.last_name)
		Sex.encode(value.sex, writer)
		Health.encode(value.health, writer)
		Religion.encode(value.religion, writer)
		Geolocation.encode(value.desired_place, writer)
		writer.write_string(value.avatar_key)
		writer.write_bool(value.zygote)
		return writer.get_bytes()
	},
	decode(input: Uint8Array, reader = new BincodeReader(input)) {
		const value: MeUser = {
			id: reader.read_u32(),
			phone: reader.read_string(),
			first_name: reader.read_string(),
			last_name: reader.read_string(),
			sex: Sex.decode(input, reader),
			health: Health.decode(input, reader),
			religion: Religion.decode(input, reader),
			desired_place: Geolocation.decode(input, reader),
			avatar_key: reader.read_string(),
			zygote: reader.read_bool(),
		}
		return value
	}
}

export const Religion = {
	encode(value: Religion, writer = new BincodeWriter()) {
		switch (value.$) {
			case "atheism": {
				writer.write_variant_index(0)
				writer.write_unit(value.$0)
				break
			}
			case "agnosticism": {
				writer.write_variant_index(1)
				writer.write_unit(value.$0)
				break
			}
			case "christianitiy": {
				writer.write_variant_index(2)
				ChrisitanReligion.encode(value.$0, writer)
				break
			}
			case "judaism": {
				writer.write_variant_index(3)
				writer.write_unit(value.$0)
				break
			}
			case "buddhism": {
				writer.write_variant_index(4)
				writer.write_unit(value.$0)
				break
			}
		}
		return writer.get_bytes()
	},
	decode(input: Uint8Array, reader = new BincodeReader(input)) {
		let value: Religion
		switch (reader.read_variant_index()) {
			case 0: {
				value = {
					$: "atheism",
					$0: reader.read_unit()
				} satisfies Extract<Religion, { $: "atheism" }>
				break
			}
			case 1: {
				value = {
					$: "agnosticism",
					$0: reader.read_unit()
				} satisfies Extract<Religion, { $: "agnosticism" }>
				break
			}
			case 2: {
				value = {
					$: "christianitiy",
					$0: ChrisitanReligion.decode(input, reader),
				} satisfies Extract<Religion, { $: "christianitiy" }>
				break
			}
			case 3: {
				value = {
					$: "judaism",
					$0: reader.read_unit()
				} satisfies Extract<Religion, { $: "judaism" }>
				break
			}
			case 4: {
				value = {
					$: "buddhism",
					$0: reader.read_unit()
				} satisfies Extract<Religion, { $: "buddhism" }>
				break
			}
		}

		return value
	}
}

export const RpcMessageContainer = {
	encode(value: RpcMessageContainer, writer = new BincodeWriter()) {
		switch (value.$) {
			case "client_request": {
				writer.write_variant_index(0)
				writer.write_u32(value.id)
				ClientRequest.encode(value.inner, writer)
				break
			}
			case "server_response": {
				writer.write_variant_index(1)
				writer.write_u32(value.answer_to)
				ServerResponse.encode(value.inner, writer)
				break
			}
			case "client_notification": {
				writer.write_variant_index(2)
				ClientNotification.encode(value.inner, writer)
				break
			}
			case "server_notification": {
				writer.write_variant_index(3)
				ServerNotification.encode(value.inner, writer)
				break
			}
			case "server_request": {
				writer.write_variant_index(4)
				writer.write_u32(value.id)
				ServerRequest.encode(value.inner, writer)
				break
			}
			case "client_response": {
				writer.write_variant_index(5)
				writer.write_u32(value.answer_to)
				ClientResponse.encode(value.inner, writer)
				break
			}
		}
		return writer.get_bytes()
	},
	decode(input: Uint8Array, reader = new BincodeReader(input)) {
		let value: RpcMessageContainer
		switch (reader.read_variant_index()) {
			case 0: {
				value = {
					$: "client_request",
					id: reader.read_u32(),
					inner: ClientRequest.decode(input, reader),
				} satisfies Extract<RpcMessageContainer, { $: "client_request" }>
				break
			}
			case 1: {
				value = {
					$: "server_response",
					answer_to: reader.read_u32(),
					inner: ServerResponse.decode(input, reader),
				} satisfies Extract<RpcMessageContainer, { $: "server_response" }>
				break
			}
			case 2: {
				value = {
					$: "client_notification",
					inner: ClientNotification.decode(input, reader),
				} satisfies Extract<RpcMessageContainer, { $: "client_notification" }>
				break
			}
			case 3: {
				value = {
					$: "server_notification",
					inner: ServerNotification.decode(input, reader),
				} satisfies Extract<RpcMessageContainer, { $: "server_notification" }>
				break
			}
			case 4: {
				value = {
					$: "server_request",
					id: reader.read_u32(),
					inner: ServerRequest.decode(input, reader),
				} satisfies Extract<RpcMessageContainer, { $: "server_request" }>
				break
			}
			case 5: {
				value = {
					$: "client_response",
					answer_to: reader.read_u32(),
					inner: ClientResponse.decode(input, reader),
				} satisfies Extract<RpcMessageContainer, { $: "client_response" }>
				break
			}
		}

		return value
	}
}

export const ServerNotification = {
	encode(value: ServerNotification, writer = new BincodeWriter()) {
		switch (value.$) {
			case "entity_updated": {
				writer.write_variant_index(0)
				writer.write_unit(value.$0)
				break
			}
			case "entity_deleted": {
				writer.write_variant_index(1)
				writer.write_u32(value.e_type)
				EntityUpdateType.encode(value.update_type, writer)
				break
			}
		}
		return writer.get_bytes()
	},
	decode(input: Uint8Array, reader = new BincodeReader(input)) {
		let value: ServerNotification
		switch (reader.read_variant_index()) {
			case 0: {
				value = {
					$: "entity_updated",
					$0: reader.read_unit()
				} satisfies Extract<ServerNotification, { $: "entity_updated" }>
				break
			}
			case 1: {
				value = {
					$: "entity_deleted",
					e_type: reader.read_u32(),
					update_type: EntityUpdateType.decode(input, reader),
				} satisfies Extract<ServerNotification, { $: "entity_deleted" }>
				break
			}
		}

		return value
	}
}

export const ServerRequest = {
	encode(value: ServerRequest, writer = new BincodeWriter()) {
		switch (value.$) {
			case "ping": {
				writer.write_variant_index(0)
				writer.write_unit(value.$0)
				break
			}
		}
		return writer.get_bytes()
	},
	decode(input: Uint8Array, reader = new BincodeReader(input)) {
		let value: ServerRequest
		switch (reader.read_variant_index()) {
			case 0: {
				value = {
					$: "ping",
					$0: reader.read_unit()
				} satisfies Extract<ServerRequest, { $: "ping" }>
				break
			}
		}

		return value
	}
}

export const ServerResponse = {
	encode(value: ServerResponse, writer = new BincodeWriter()) {
		switch (value.$) {
			case "error": {
				writer.write_variant_index(0)
				Error.encode(value.$0, writer)
				break
			}
			case "pong": {
				writer.write_variant_index(1)
				writer.write_unit(value.$0)
				break
			}
			case "user": {
				writer.write_variant_index(2)
				if (value.$0) {
					writer.write_option_tag(true)
					User.encode(value.$0, writer)
				} 
				else writer.write_option_tag(false)
				              
				break
			}
			case "updated_user": {
				writer.write_variant_index(3)
				if (value.$0) {
					writer.write_option_tag(true)
					MeUser.encode(value.$0, writer)
				} 
				else writer.write_option_tag(false)
				              
				break
			}
			case "geolocations": {
				writer.write_variant_index(4)
				writer.write_length(value.$0.length)
				for (const item of value.$0) {
					Geolocation.encode(item, writer)
				}
				break
			}
		}
		return writer.get_bytes()
	},
	decode(input: Uint8Array, reader = new BincodeReader(input)) {
		let value: ServerResponse
		switch (reader.read_variant_index()) {
			case 0: {
				value = {
					$: "error",
					$0: Error.decode(input, reader),
				} satisfies Extract<ServerResponse, { $: "error" }>
				break
			}
			case 1: {
				value = {
					$: "pong",
					$0: reader.read_unit()
				} satisfies Extract<ServerResponse, { $: "pong" }>
				break
			}
			case 2: {
				value = {
					$: "user",
					$0: reader.read_option_tag() ? User.decode(input, reader) : null,
				} satisfies Extract<ServerResponse, { $: "user" }>
				break
			}
			case 3: {
				value = {
					$: "updated_user",
					$0: reader.read_option_tag() ? MeUser.decode(input, reader) : null,
				} satisfies Extract<ServerResponse, { $: "updated_user" }>
				break
			}
			case 4: {
				value = {
					$: "geolocations",
					$0: reader.read_list<Geolocation>(() => Geolocation.decode(input, reader)),
				} satisfies Extract<ServerResponse, { $: "geolocations" }>
				break
			}
		}

		return value
	}
}

export const Sex = {
	encode(value: Sex, writer = new BincodeWriter()) {
		switch (value.$) {
			case "male": {
				writer.write_variant_index(0)
				writer.write_unit(value.$0)
				break
			}
			case "female": {
				writer.write_variant_index(1)
				writer.write_unit(value.$0)
				break
			}
		}
		return writer.get_bytes()
	},
	decode(input: Uint8Array, reader = new BincodeReader(input)) {
		let value: Sex
		switch (reader.read_variant_index()) {
			case 0: {
				value = {
					$: "male",
					$0: reader.read_unit()
				} satisfies Extract<Sex, { $: "male" }>
				break
			}
			case 1: {
				value = {
					$: "female",
					$0: reader.read_unit()
				} satisfies Extract<Sex, { $: "female" }>
				break
			}
		}

		return value
	}
}

export const UpdateUser = {
	encode(value: UpdateUser, writer = new BincodeWriter()) {
		if (value.first_name) {
			writer.write_option_tag(true)
			writer.write_string(value.first_name)
		} 
		else writer.write_option_tag(false)
		              
		if (value.last_name) {
			writer.write_option_tag(true)
			writer.write_string(value.last_name)
		} 
		else writer.write_option_tag(false)
		              
		return writer.get_bytes()
	},
	decode(input: Uint8Array, reader = new BincodeReader(input)) {
		const value: UpdateUser = {
			first_name: reader.read_option_tag() ? reader.read_string() : null,
			last_name: reader.read_option_tag() ? reader.read_string() : null,
		}
		return value
	}
}

export const User = {
	encode(value: User, writer = new BincodeWriter()) {
		writer.write_u32(value.id)
		writer.write_string(value.first_name)
		writer.write_string(value.last_name)
		writer.write_string(value.avatar_key)
		writer.write_bool(value.zygote)
		return writer.get_bytes()
	},
	decode(input: Uint8Array, reader = new BincodeReader(input)) {
		const value: User = {
			id: reader.read_u32(),
			first_name: reader.read_string(),
			last_name: reader.read_string(),
			avatar_key: reader.read_string(),
			zygote: reader.read_bool(),
		}
		return value
	}
}
