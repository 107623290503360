import { createMutable } from "solid-js/store"
import { BasicInput } from "../basic-input"
import { CommonModalWrapper, createOverlay } from "#/lib/overlay"
import { useRpc } from "#/lib/mod"
import { Menu } from "../menu"

export function useReportMenu(entity_type: proto.EntityType, entity_num: () => number) {
	let overlay = createOverlay()
	let rpc = useRpc()

	let state = createMutable({
		reason: null as string,
	})

	let err = createMutable({
		reason: null,
	})

	let check = {
		description() {
			err.reason = function() {
				if (state.reason?.length === 0) {
					return "Введите причину"
				}
			}()
		},
	}

	function show() {
		overlay.set({ show: true, left: "50%", top: "35%" })
	}

	let VirtualRoot = () =>
		<overlay.VirtualRoot
			centered
			Content={() =>
				<Menu class="p-inline-4 pb2">
					<div class="text-3xl font-500 flex items-center gap-2">
						<i class="i-hero:exclaimation-triangle" />
						Пожаловаться
					</div>

					<label class="mt4 font-500" innerText="Причина" />
					<BasicInput
						Input={p => <textarea {...p} />}
						placeholder="Например: Детская порнография"
						class="overflow-hidden h20 mt2 leading-6 min-w-60!"
						error={err.reason}
						onInput={(e, { currentTarget: { value } } = e) => {
							state.reason = value
							err.reason = null
						}}
						onChange={check.description}
					/>

					<div
						class=":c: p-inline-2 p-block-1 rounded-12px bg-blue-450 ptr ml-auto"
						innerText={"Отправить"}
						onClick={async () => {
							rpc.sendAway({
								$case: "cmd_submit_report",
								cmd_submit_report: {
									entity_type,
									entity_num: entity_num(),
									reason: state.reason,
								},
							})

							overlay.show = false
						}}
					/>
				</Menu>}
		/>

	return {
		VirtualRoot,
		show,
	}
}
