import { drop, type ComposableComponentProps } from "#/lib/mod"

export function ContinueButton(props: ComposableComponentProps<"button"> & { class?: string }) {
	let other = drop(props, "classList", "onContextMenu", "class")

	// class="cursor-no-drop"
	return (
		<button
			type="button"
			onContextMenu={e => e.preventDefault()}
			use:ripple
			{...other}
			classList={{
				":c: w-auto relative h-12 rounded-2.5 shrink-0 mt-auto mb-8 ptr": true,
				":c: b-style-none dark:(bg-white c-dark)": true,
				":c: disabled:(dark:bg-gray-400 light:(bg-blue-100 c-blue-000) cursor-no-drop)": true,
				[props.class]: props.class != null,
				...props.classList
			}}
		/>
	)
}
