import { batch, createContext, useContext, type ParentProps } from "solid-js"
import { createMutable, unwrap } from "solid-js/store"

import { mergeMutableAdditive } from "#/lib/rx/data"

import type { R } from "../remote/mod"
import { IdbStoreKeys } from "../mod"

export function CacheContextProvider(props: ParentProps) {
	let trace = tracing("CacheContext")

	let idb: IDBDatabase

	let entities = createMutable({
		users: [] as (R.MeUser)[],

		presense_list: [],

		// chats: [] as shapes.MinChat[],
		// messages: [] as shapes.Message[],
		// chat_memberships: [] as shapes.MinChatMembership[],
	})

	let misc = createMutable({
		watched_deals: [] as number[],
	})

	// TODO
	Object.assign(window, {
		get cache() {
			return unwrap({ ...entities, ...misc })
		},
		get entities() {
			return entities
		},
	})

	load()

	type EntitiesStorage = typeof entities
	type SpecialKeys = Partial<
		{
			[key in keyof EntitiesStorage]: keyof EntitiesStorage[key][number] extends string
				? keyof EntitiesStorage[key][number]
				: never
		}
	>
	let special_keys: SpecialKeys = {
		// chat_memberships: "chat_id",
	}

	function resolve<K extends keyof EntitiesStorage>(
		name: K,
		id: number,
		key?: keyof EntitiesStorage[K][number],
	): EntitiesStorage[K][number]
	function resolve<K extends keyof EntitiesStorage>(
		name: K,
		ids: number[],
		key?: keyof EntitiesStorage[K][number],
	): EntitiesStorage[K]
	function resolve<K extends keyof EntitiesStorage>(
		name: K,
		ids: number | number[],
		key = special_keys[name] ?? "id",
	) {
		if (Array.isArray(ids)) {
			let resolved = ids.flatMap(num => entities[name].filter(item => item[key as keyof typeof item] === num))
			return resolved.filter(Boolean)
		}
		let resolved = entities[name].find(item => item[key as keyof typeof item] === ids)
		return resolved
	}

	function update<K extends keyof EntitiesStorage>(key: K, values: EntitiesStorage[K]) {
		type V = EntitiesStorage[K][number]
		mergeMutableAdditive<V, V>(entities[key], values, {
			merge: true,
			key: special_keys[key] ?? "id",
		})
	}

	function updateBatch<K extends keyof EntitiesStorage>(obj: Record<K, EntitiesStorage[K]>) {
		batch(() => Object.keys(obj).forEach(key => (key in entities) && update(key as K, obj[key])))
	}

	function drop() {
		batch(() => {
			for (let storage of Object.getOwnPropertyNames(entities)) {
				entities[storage].splice(0, entities[storage].length)
			}
		})
		trace.info("in-memory cache cleared")
	}

	async function load() {
		let db_request = indexedDB.open("mymay", 8)

		await new Promise<void>((resolve) => {
			db_request.onupgradeneeded = change_event => {
				idb = db_request.result

				let { oldVersion, newVersion } = change_event
				trace.warn(`indexed db upgrade needed: ${oldVersion} -> ${newVersion}`)

				let { objectStoreNames } = idb
				Object.keys(entities).forEach(entity_name => {
					objectStoreNames.contains(entity_name) && idb.deleteObjectStore(entity_name)
					idb.createObjectStore(entity_name, { keyPath: "id" })
				})

				Object.values(IdbStoreKeys).forEach(name => {
					objectStoreNames.contains(name) && idb.deleteObjectStore(name)
					idb.createObjectStore(name)
					trace.info(name)
				})

				resolve()
			}
			db_request.onsuccess = () => {
				idb = db_request.result
				resolve()
			}
			db_request.onerror = err => {
				trace.error("db_request error", err)
			}
		})

		trace.debug("Initialized IndexedDB")
	}

	let ctx = {
		entities,
		misc,
		resolve,
		update,
		updateBatch,
		drop,
		get idb() {
			return idb
		},
	}

	return Object.assign(<CacheContext.Provider value={ctx} children={props.children} />, { ctx })
}

let CacheContext = createContext<ReturnType<typeof CacheContextProvider>["ctx"]>()
export let useCache = () => useContext(CacheContext)
