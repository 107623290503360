/* @refresh reload */

import "uno.css"
import "#/assets/styles/index.css"
import "#/assets/styles/fonts.css"

import "./lib/std"
import "./lib/globals"

import { lazy } from "solid-js"
import { render } from "solid-js/web"
import { Toaster } from "solid-toast"

import { env } from "#/lib/mod"
import { MetaProvider, Title } from "#/lib/meta"
import { ThemeContextProvider } from "#/lib/appearance/theme"
import { AuthContextProvider } from "#/lib/auth"
import { I18n } from "#/lib/appearance/i18n"
import { registerWorker } from "#/worker/mod"

// Contexts
import { LayoutContextProvider } from "#/layout.context"
import { CacheContextProvider } from "#/lib/cache/cache.context"
import { RpcContextProvider } from "#/lib/remote/rpc.context"
import { MessagesContextProvider } from "#/domains/chats/chats.context"

import { MOBILE_NAVBAR_HEIGHT } from "./common/mod"

void function bootstrap() {
	render(App, document.body)

	let worker_url = "/worker." + (import.meta.env.DEV ? "ts" : "js")
	registerWorker(new Worker(worker_url, { type: "module", name: "mm-worker" }))

	// TODO: https://bugzilla.mozilla.org/show_bug.cgi?id=1360870#c5
	if (!env.rt.is_firefox) {
		let sw_url = "/sw." + (import.meta.env.DEV ? "ts" : "js")
		navigator.serviceWorker.register(sw_url, { type: "module", scope: "/" })
	}

}()

function Navigator() {
	// let app = useLayout()

	let MobileNavigator = lazy(() => import("./common/layout/navigator.mobile"))
	return <MobileNavigator />

	// let DesktopNavigator = lazy(() => import("./common/layout/navigator.desktop"))

	// return <Switch>
	// 	<Match when={/*@once*/ app.is_mobile}>
	// 		<MobileNavigator />
	// 	</Match>
	// 	<Match when={/*@once*/ app.is_desktop}>
	// 		<DesktopNavigator />
	// 	</Match>
	// </Switch>
}

function App() {
	return [
		// if problem with cycling dependencies would appear, consider to use single context that will hold
		// event emitters / channels for each context. it should be called actor model idk
		<MetaProvider>
			<Title innerText={import.meta.env.DEV ? "[локал] Мой Май" : "Мой Май"} />
			<I18n>
				<LayoutContextProvider>
					<ThemeContextProvider>
						<CacheContextProvider>
							<AuthContextProvider>
								<RpcContextProvider>
									<MessagesContextProvider>
										<Navigator />
									</MessagesContextProvider>
								</RpcContextProvider>
							</AuthContextProvider>
						</CacheContextProvider>
					</ThemeContextProvider>
				</LayoutContextProvider>
			</I18n>
		</MetaProvider>,
		<Toaster
			toastOptions={{
				position: "bottom-center",
				duration: 1550
			}}
			containerStyle={{
				bottom: `calc(10px + ${MOBILE_NAVBAR_HEIGHT})`,
			}}
		/>,
	]
}
