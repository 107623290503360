import { createEffect, createMemo, For, Show } from "solid-js"
import { createMutable } from "solid-js/store"
import toast from "solid-toast"

import { useAuth, api, useCache } from "#/lib/mod"

import type { shapes } from "#/lib/data/shapes"

export function createInterestsSelect() {
	let auth = useAuth()
	let cache = useCache()
	let dedupeSpheres = () => cache.entities.tags.map(tag => tag.sphere).filter((s, i, arr) => arr.indexOf(s) === i)

	let state = createMutable({
		opened_spheres: [],
		selected_tag_nums: [...auth.user.interest_tag_nums],
		loading: false,
	})

	let selectedTags = createMemo(() => cache.resolve("tags", state.selected_tag_nums))

	async function load() {
		state.loading = true

		let tags = await api.req<shapes.Tag[]>("/api/tags", { qs: { base_only: true } })

		if (errorHandled(tags, "Загрузка сфер дел и интересов")) {
			state.loading = false
			return
		}

		if (!tags) {
			toast.error("Произошла ошибка при загрузке сфер дел и интересов")
			state.loading = false
			return
		}

		cache.update("tags", tags)

		let initial_tags = cache.resolve("tags", auth.user.interest_tag_nums)
		let initial_opened_spheres = dedupeSpheres().filter(s => initial_tags.some(tag => tag.sphere === s))
		state.opened_spheres = initial_opened_spheres

		state.loading = false
	}
	queueMicrotask(load)

	let Component = () => (
		<div class="flex flex-col items-stretch gap-2 mt-8">
			<For
				each={dedupeSpheres()}
				children={(sphere) => {
					let container: HTMLDivElement

					let isOpened = createMemo(() => state.opened_spheres.includes(sphere))
					let tagsInside = createMemo(() => selectedTags().filter(t => t.sphere == sphere))
					let hasSelectedTagsInside = createMemo(() => tagsInside().length > 0)

					createEffect(() => {
						if (isOpened()) {
							requestAnimationFrame(() => container.style.height = container.scrollHeight + "px")
						}
						else {
							requestAnimationFrame(() => container.style.height = "")
						}
					})

					return (
						<div
							ref={r => container = r}
							class="rounded-2 h-12 overflow-hidden"
							classList={{
								[":c: outline-(1px solid) light:outline-gray-200 dark:outline-gray-600"]: !hasSelectedTagsInside(),
								[":c: c-blue-500 outline-(1px blue-500 solid)"]: hasSelectedTagsInside(),
								[":c: light:bg-blue-000 dark:bg-blue-900"]: !isOpened() && hasSelectedTagsInside(),
							}}
							style={{ transition: "height 120ms ease" }}
						>
							<sphere
								class="rounded-2 h-12 flex items-center p-inline-4 justify-between select-none"
								content-after={tagsInside().length}
								innerText={sphere === "__global__" ? "Разное" : sphere}
								classList={{
									[":c: after:(min-w-fit content-[attr(content-after)] h5 w6 rounded-131px bg-blue-450 flex-center c-white-000)"]: !isOpened()
										&& hasSelectedTagsInside(),
								}}
								onClick={() => {
									if (!isOpened()) {
										state.opened_spheres.push(sphere)
										return
									}
									state.opened_spheres.spliceSafe(state.opened_spheres.indexOf(sphere), 1)
								}}
							/>
							<Show when={isOpened()}>
								<div class="flex flex-wrap gap-2 p-block-4 p-inline-4">
									<For
										each={cache.entities.tags.filter(t => t.sphere === sphere || (!sphere && !t.sphere))}
										children={tag => {
											let isSelected = () => selectedTags().includes(tag)
											return (
												<tag
													class=":c: p-inline-3 p-block-2.125 rounded-4.5 text-3 font-500 flex items-center relative select-none dark:(c-white-000 bg-gray-800) light:(c-black-999 bg-gray-000)"
													classList={{
														["bg-blue-500! c-white-000! outline-(1px blue-500 solid)"]: isSelected(),
													}}
													innerText={tag.name}
													onClick={() => {
														if (!isSelected()) {
															state.selected_tag_nums.push(tag.num)
															return
														}
														state.selected_tag_nums.spliceSafe(state.selected_tag_nums.indexOf(tag.num), 1)
													}}
												/>
											)
										}}
									/>
								</div>
							</Show>
						</div>
					)
				}}
			/>
		</div>
	)

	return Object.assign(state, {
		Component,
		selectedTags,
	})
}
