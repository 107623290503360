import { createRenderEffect, Match, Switch, type ParentProps } from "solid-js"
import { unwrap } from "solid-js/store"

import { useAuth, langs, useRouter, Navigate, ROUTES } from "#/lib/mod"

import { InfoOverlay, LoadingScreen } from "../info-overlay"

export function AuthenticationGuard(props: ParentProps) {
	let trace = tracing("AuthenticationGuard")
	let auth = useAuth()
	let router = useRouter()

	createRenderEffect(() => {
		if (auth.is_auth_pending) {
			trace.debug("Waiting for loading")
			return
		}
		if (auth.is_authenticated) {
			trace.debug("Sucessfully authenticated", unwrap(auth.user))
			return
		}
		trace.debug("Not authenticated.", unwrap(auth.user))
	})

	return (
		<Switch>
			<Match when={router.blocked()}
				children={<LoadingScreen />}
			/>
			<Match when={auth.is_auth_pending}
				children={<AuthenticationMockScreen />}
			/>
			<Match when={!auth.is_authenticated}
				children={[<NotAuthenticationMockScreen />, <Navigate to={ROUTES.login.template} />]}
			/>
			<Match when={auth.is_authenticated}
				children={props.children}
			/>
		</Switch>
	)
}

function AuthenticationMockScreen() {
	return (
		<InfoOverlay class=":c: flex flex-col items-center dark:c-gray-200 light:c-gray-600">
			<i class=":c: i-hero:face-smile h20 w20" />
			<div class=":c: mt2 text-3xl font-medium" innerText={t().authenticating} />
		</InfoOverlay>
	)
}
function NotAuthenticationMockScreen() {
	return (
		<InfoOverlay class=":c: flex flex-col items-center dark:c-gray-200 light:c-gray-600">
			<i class=":c: i-hero:face-frown h20 w20" />
			<div class=":c: mt2 text-3xl font-medium" innerText={t().not_authenticated} />
		</InfoOverlay>
	)
}

let t = langs({
	ru: {
		not_authenticated: "Мы не знакомы 🙈",
		authenticating: "Авторизация",
	},
	en: {
		not_authenticated: "You're not authenticated 🙈",
		authenticating: "Trying to reconize you as user",
	},
	ua: {
		not_authenticated: "Ми не знаємо вас 🙈",
		authenticating: "Спробуємо розпізнати вас",
	},
})
