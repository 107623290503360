import toast from "solid-toast"
import { createMutable } from "solid-js/store"

import { useAuth, api, drop, errorHandled, type ComposableComponentProps } from "#/lib/mod"

import { UserAvatar } from "#/components/mod"

export function createAvatarUpload() {
	let auth = useAuth()
	let state = createMutable({
		loading: false,
	})

	async function onPictureSelected(e: Event & { currentTarget: HTMLInputElement }) {
		state.loading = true
		let toast_id = toast.loading("Загрузка аватара...", { duration: Number.POSITIVE_INFINITY })

		let source_image = e.currentTarget.files.item(0)
		if (!source_image) {
			toast.error("No picture selected")
			return
		}

		let formData = new FormData()
		formData.set("picture", source_image)

		let response = await api.req("set_avatar", {
			method: "POST",
			body: formData,
			modify(params) {
				delete params.headers["content-type"]
			},
		})
		toast.remove(toast_id)

		if (errorHandled(response)) {
			state.loading = false
			return
		}

		auth.user.avatar_key = response
		state.loading = false
		toast.success("Аватар изменен.")
	}

	let ctx = Object.assign(state, { Component })

	function Component(props: ComposableComponentProps<"div"> & { class?: string }) {
		let other = drop(props, "class", "classList")
		return (
			<div {...other} classList={{
				":c: flex items-center self-center gap4": true,
				[props.class]: !!props.class,
				...props.classList,
			}}>
				<UserAvatar user={auth.user} class="size-30" />
				<div>
					<div class=":c: flex items-center gap-2 relative">
						<input
							classList={{
								":c: absolute inset-0 appearance-none c-transparent b-none z-2 ptr": true,
								":c: [&::-webkit-file-upload-button]:display-[none] [&::file-selector-button]:display-[none]": true,
							}}
							type="file"
							name="avatar"
							accept="image/png, image/jpeg, image/webp, image/gif"
							multiple={false}
							onChange={onPictureSelected}
						/>
						<i class="i-hero:photo" />
						<div innerText={"Выбрать"} />
					</div>
					<div
						class=":c: flex items-center gap-2 c-red-500 mt-5 ptr"
						onClick={async () => {
							state.loading = true
							let result = await api.req("avatar")
							if (errorHandled(result)) {
								state.loading = false
								return
							}
							await auth.refetchUser()
							state.loading = false
						}}
					>
						<i class="i-hero:trash" />
						<div innerText="Удалить" />
					</div>
				</div>
			</div >
		)
	}

	return ctx
}
