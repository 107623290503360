import { createMutable } from "solid-js/store"
import toast from "solid-toast"

import { env, useAuth } from "#/lib/mod"

const CONNECTION_TIMEOUT_MS = 3000

export type SocketConnectionStatus = {
	_: "disconnected"
	reason?: "sleep" | "cleanup"
} | {
	_: "connecting"
} | {
	_: "connected"
}
type EjectedReason<T extends SocketConnectionStatus = SocketConnectionStatus> = T extends
	{ _: "disconnected"; reason?: infer U } ? U : never

// TODO: implement ping timeouts to handle connection change (VPN etc)
export function createWebsocketController({ onMessage }) {
	let auth = useAuth()
	let trace = tracing("WebsocketController")

	let ctx = createMutable({
		ws: null as WebSocket,
		should_abort_connecting_attempt: false,
		status: { _: "disconnected" } as SocketConnectionStatus,

		tryConnect,
		disconnect,
	})

	async function connect() {
		if (ctx.ws?.readyState === WebSocket.CONNECTING) {
			trace.warn("there is already connecting socket")
			return
		}

		let websocket_path = `${location.protocol === "https:" ? "wss" : "ws"}://${location.host}/api/ws`
		let ws = new WebSocket(websocket_path, ["mymay", auth.jwt, env.version, env.rt.platform.toString()]) // https://github.com/whatwg/websockets/issues/16#issuecomment-1997491158
		ws.binaryType = "arraybuffer"

		let { promise, reject, resolve } = Promise.withResolvers<WebSocket>()

		ws.onopen = () => {
			clearTimeout(timeout)
			ws.onerror = null
			ws.onclose = null
			resolve(ws)
		}

		ws.onclose = e => {
			let error_str = "WebSocket disconnected quickly."
			if (e.reason?.length) {
				error_str += ` Reason:  ${e.reason}`
			}
			if (e.code) {
				error_str += ` Code: ${e.code}`
			}
			reject(new Error(error_str))
		}

		let timeout = setTimeout(() => {
			if (ws !== ctx.ws) return
			if (ws?.readyState == WebSocket.CONNECTING) return

			trace.warn("timeout connecting to", websocket_path)
			ws?.close(1000)
			reject(new Error("timeout connecting to websocket"))
		}, CONNECTION_TIMEOUT_MS)

		return promise
	}

	// TODO: check if need to disconnect (logout etc)
	async function tryConnect(force = false) {
		if (ctx.status._ === "connecting") {
			trace.warn("skipping connect() as we're already connecting")
			return
		}

		if (!force && ctx.status._ === "connected") {
			trace.warn("Skipping connect() as we're already connected")
			return
		}

		ctx.should_abort_connecting_attempt = false
		ctx.status = { _: "connecting" }

		for (let attempt = 1; true; attempt++) {
			// Treat setting "Disconnected" externally as signal to abort attempts
			if (ctx.should_abort_connecting_attempt) {
				trace.warn("Aborting connection attempts")
				break
			}

			let result = await connect().catch(Function.NOOP_ERR)
			if (result instanceof WebSocket) {
				trace.info("Connected")
				if (attempt > 1)
					toast.success("Подключение восстановлено")

				ctx.ws = result
				ctx.status = { _: "connected" }
				ctx.ws.onclose = onSuddenClose
				ctx.ws.onmessage = onMessage
				return
			}

			let timeout_ms = 2 ** attempt * 400 + Math.random() * 1500
			trace.warn("attempt", attempt, "failed to connect", result.toString())
			await Promise.delay(timeout_ms)
		}
	}

	function onSuddenClose(e: CloseEvent) {
		ctx.status = { _: "disconnected" }

		if (!e.wasClean) {
			trace.warn("connection closed NOT cleanly", e)
			tryConnect()
		}
		else {
			trace.warn("weboscket connection closed cleanly")
		}
	}

	function disconnect(reason: EjectedReason | null = null) {
		trace.debug("Disconnecting")
		ctx.should_abort_connecting_attempt = true
		if (ctx.ws) {
			ctx.ws.onclose = null
			ctx.ws.close(1000 /* NORMAL */)
		}
		ctx.status = { _: "disconnected", reason }
	}

	return ctx
}
