// reexport lib modules

export * from "./tracing"
export * from "./auth"
export * from "./behaviour"
export * from "./cookies"
export * from "./schedulers"
export * from "./overlay"
export * from "./md"

// reexport common modules
export { ROUTES, useInPageView, ViewTransition, useRouter, Navigate, onPageWasNavigated } from "./navigation/mod"
export { R } from "./remote/mod"
export { api } from "./remote/api"
export { rpc, useRpc } from "./remote/rpc.context"
export {
	signal,

	drop, recompose,

	Ref, mapData, trackDeep,

	type ComponentLike,
	type ComposableComponentLike,
	type ComposableComponentProps,
} from "./rx/mod"
export { langs, lang } from "./appearance/i18n"

export { useCache } from "./cache/cache.context"
export { useLayout } from "../layout.context"
export function haversineDistanceKm(x: { lon: number; lat: number }, y: { lon: number; lat: number }) {
	let rad = Math.PI / 180, R = 6371 // Radius of the Earth in kilometers
	let dLat = (y.lat - x.lat) * rad, dLon = (y.lon - x.lon) * rad

	let a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
		+ Math.cos(x.lat * rad) * Math.cos(y.lat * rad) * Math.sin(dLon / 2) * Math.sin(dLon / 2)

	let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

	let distance = R * c // Distance in kilometers
	return distance
}

export { type shapes } from "./data/shapes"


// Lib things too
import { toast } from "solid-toast"
import { env } from "./behaviour"
import { type ApiError } from "./remote/api"
import { type RpcError } from "./remote/rpc.context"
import EventEmitter from "eventemitter3"


export const
	TEXT_ENCODER = new TextEncoder(),
	TEXT_DECODER = new TextDecoder()

export const
	MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24,
	DEFAULT_DATE_LOCALE = "ru-RU"

/**
```md
TODO: refactor to i18n module?
cardinal is number of items
ordinal is order of items
resolved categories/types could be debuggied via `plural_rule.resolvedOptions().pluralCategories`
ru-RU: one, few, many, other
```
*/
export let PLURALS = {
	CardinalRule: new Intl.PluralRules(DEFAULT_DATE_LOCALE, { type: "cardinal" }),
	OrdinalRule: new Intl.PluralRules(DEFAULT_DATE_LOCALE, { type: "ordinal" }),
}

export function doNextFrame<Fn extends FrameRequestCallback>(fn: Fn) {
	if (env.rt.is_firefox)
		return requestAnimationFrame(() => requestAnimationFrame(fn))

	return requestAnimationFrame(fn)
}

export let onlyDev = <T, F = T>(value: T, fallback?: F) =>
	import.meta.env.DEV ? value : fallback ?? (value?.constructor?.() as T)

export function cl(...strs: string[]) {
	return Object.fromEntries(strs.map(s => ([s, true]))) as Record<string, true>
}

export function truncateText(text: string, { max_chars = 200, max_words = 5, squeeze = true, ellipsis = false } = {}) {
	if (squeeze)
		text = text.replace(/\n+/g, "\n")

	let cap_iof = -1, i = 0
	for (let len = text.length, wc = 0, last_word_len = 0; i < len; i++) {
		if (text[i] !== " ") {
			last_word_len = last_word_len + 1
		}
		else {
			if (i === len - 1) break

			while (text[i + 1] === " ") {
				i++
				continue
			}

			if (last_word_len > 3) wc++

			last_word_len = 0

			if (wc >= max_words || i >= max_chars) {
				cap_iof = i
				break
			}
		}
	}

	if (ellipsis && cap_iof > -1) {
		text = text.slice(0, cap_iof) + "..."
		cap_iof += 3
	}

	return {
		text,
		truncated: cap_iof > -1,
	}
}

export function isDiscriminant<R, E, P extends keyof E>(target: R | E | {}, prefix: P): target is E {
	return target && typeof target === "object" && prefix in target;
}
export let isApiError = <T, E extends ApiError>(target: T | E) => isDiscriminant<T | E, E, "$api_error">(target, "$api_error")
export let isRpcError = <T, E extends RpcError>(target: T | E) => isDiscriminant<T | E, E, "$rpc_error">(target, "$rpc_error")

export function errorHandled<
	T,
	R extends (T | ApiError) | (T | RpcError),
	E extends R extends ApiError ? R : R extends RpcError ? R : never
>(response: R, prefix?: string): response is E {
	if (isApiError(response)) {
		let parts = [], postfix: string
		if (prefix) parts.push(prefix)
		switch (response.$api_error) {
			case "network":
				postfix = `Проблемы с подключением`
				break
			case "bad_response":
				postfix = `Неопозанный ответ от сервера. Попробуйте позже`
				break
			case "unknown":
				postfix = `Неизвестная ошибка`
				break
			case "remote":
				postfix = `${response.remote_error.message}`
				break
		}
		parts.push(postfix)
		toast.error(parts.join("\n"), { duration: 2500 })
		return true
	}
	else if (isRpcError(response)) {
		let parts = [], postfix: string
		if (prefix) parts.push(prefix)
		switch (response.$rpc_error) {
			case "aborted":
				return true
			case "not_connected":
				postfix = `Отсутствует подключение к серверу`
				break
			case "payload_too_big":
				postfix = `Слишком большое сообщение`
				break
			case "timeout":
				postfix = `Превышено время ожидания ответа от сервера, попробуйте позже`
				break
			case "remote":
				postfix = `${response.remote_error.message}`
				break
		}
		parts.push(postfix)
		toast.error(parts.join("\n"), { duration: 2500 })
		return true
	}

	return false
}

export let stringToColor = (text: string, lightness = 55, saturation = 76) =>
	crypto.subtle?.digest("SHA-1", TEXT_ENCODER.encode(text))
		.then(x => new Uint8Array(x))
		.then(x => x.join("").slice(16))
		.then(Number)
		.then(hash => `hsl(${hash % 360},${saturation}%,${lightness}%)`)


export type CancelSignal = ReturnType<typeof CancelSignal>
export function CancelSignal() {
	let emitter = new EventEmitter<{ "cancelled": [] }>()
	let signal = {
		cancelled: false,
		cancel() {
			emitter.emit("cancelled")
			signal.cancelled = true
		},
		onCancelled: emitter.on.bind(emitter, "cancelled"),
	}
	return signal
}


export let LocalStorageKeys = {
	OTP_KEY: "otp_key",
	JWT: "jwt",
	LOGIN_VIEW: "login_view",
	ONBOARDING_VIEW: "onboarding_view"
} as const

export let IdbStoreKeys = {
	// ONBOARDING_FORM: "onboarding_form"
} as const
