import { drop, type ComposableComponentProps } from "#/lib/mod"
import { createMemo, For, onMount } from "solid-js"
import { createMutable } from "solid-js/store"

type CustomPinInputsProps = {
	length: number
	onCompleted?: (code: string) => void
} & ComposableComponentProps<"div"> & { class?: string }

export function PinInput(props: CustomPinInputsProps) {
	let other = drop(props, "length", "onCompleted", "class", "classList")

	let state = createMutable({
		focused: null as number,
		inputs: new Array(props.length).fill(null).map(_ => ({ value: "", ref: null as HTMLInputElement })),
	})

	let focusAt = (i: number) => state.inputs[i].ref.focus()
	let isDigit = (as_number: number) => !isNaN(as_number) && as_number >= 0 && as_number < 10

	let allFilled = createMemo(() =>
		state.inputs.reduce((acc, inp) => {
			let maybe_digit = Number(inp.value)
			if (!isDigit(maybe_digit)) return acc

			return acc + 1
		}, 0) === state.inputs.length
	)

	// Achtung + rofl
	// keep preventScroll here!
	onMount(() => {
		state.inputs[0].ref?.focus({ preventScroll: true })
	})

	return (
		<div {...other}
			classList={{
				":c: flex justify-center gap-2 w-fit": true,
				[props.class]: !!props.class,
				...props.classList,
			}}
		>
			<For each={state.inputs}>
				{(input, index) => (
					<div
						classList={{
							"relative after:(absolute bottom-0 left-0 w-full h-4px rounded-4px dark:bg-gray-700 content-empty)": true,
							"uno-layer-v2:after:bg-white": input.value !== "",
						}}
					>
						<input
							class=":c: p-inline-0 pt-0 pb-2 w-13 text-center outline-none border-none text-9"
							inputmode="numeric"
							autocomplete="one-time-code"
							ref={r => input.ref = r}
							value={input.value}
							onKeyDown={({ key, currentTarget: target }) => {
								let curr = index()
								if (key === "ArrowRight") {
									if (curr < props.length - 1) {
										focusAt(curr + 1)
									}
									return
								}
								if (key === "ArrowLeft") {
									if (curr > 0) {
										focusAt(curr - 1)
									}
									return
								}
								if (key === "Backspace" || key === "Process") {
									if (target.value !== "") {
										return
									}
									if (index() > 0) {
										focusAt(index() - 1)
										return
									}
								}
							}}
							// Chrome + Android keyboard are pretty buggy
							// We can't handle onKeyDown https://bugs.chromium.org/p/chromium/issues/detail?id=118639
							// Also, some keyboard doesn't even send Backspace event
							onInput={(e, { inputType, data: symbol, currentTarget: target } = e) => {
								if (inputType === "deleteContentBackward") {
									if (input.value !== "") {
										input.value = target.value
										return
									}
								}

								if (inputType !== "insertText") {
									target.value = input.value
									return
								}

								let as_number = Number(symbol)

								if (!isDigit(as_number)) {
									target.value = input.value
									e.preventDefault()
									return
								}

								if (input.value.length === 0) {
									input.value = symbol
									target.value = symbol
									if (index() < props.length - 1) {
										focusAt(index() + 1)
									}
									else if (allFilled()) {
										props.onCompleted?.(state.inputs.map(input => input.value).join(""))
									}
									return
								}

								if (index() != props.length - 1) {
									target.value = input.value
									if (state.inputs[index() + 1].value !== "") {
										return
									}
									focusAt(index() + 1)
									state.inputs[index() + 1].value = symbol
									return
								}
								target.value = input.value
							}}
							onPaste={e => {
								let str_value = e.clipboardData.getData("text")
								if (str_value?.length !== props.length) {
									return e.preventDefault()
								}
								let as_number = Number(str_value)
								if (isNaN(as_number) || as_number / 1000 >= 10) {
									return e.preventDefault()
								}
								for (let i = 0; i < props.length; i++) {
									state.inputs[i].value = str_value[i]
								}
								e.preventDefault()
								if (allFilled()) {
									props.onCompleted?.(state.inputs.map(input => input.value).join(""))
								}
							}}
						/>
					</div>
				)}
			</For>
		</div>
	)
}
