import { R } from "./mod"

type FetchParams = Omit<RequestInit, "body"> & {
	qs?: Record<string, string | number | boolean>
	modify?(params: FetchParams): void
	body?: any
}

export type ApiError = {
	$api_error: "remote"
	remote_error: R.Error
} | {
	$api_error: "network"
	err: Error
} | {
	$api_error: "parse"
	inner: Error
	response: Response
} | {
	$api_error: "bad_response"
	response: Response
} | {
	$api_error: "unknown"
	response: Response
}
export let api = function() {
	let headers = {}

	type ResponseContainer<R extends R.ApiResponse, K> = R extends { $: K } ? R : never

	async function req<
		TResponseKey extends R.ApiResponse["$"],
		TResponseContainer = ResponseContainer<R.ApiResponse, TResponseKey>
		// Need to set explicit return type, otherwise typescript fails, as always.
	>(url_str: TResponseKey, opts: FetchParams = {}): Promise<TResponseContainer | ApiError> {
		opts.method ??= "POST"
		let url = new URL("/api/" + url_str, location.origin)

		if (opts.headers)
			Object.assign(opts.headers, headers)
		else
			opts.headers = structuredClone(headers)

		opts.modify?.(opts)

		let response = await fetch(url, opts).catch(Function.NOOP_ERR) as Response | Error

		if (response instanceof Error) {
			return { $api_error: "network", err: response }
		}

		if (response.headers.get("content-length") === "0") {
			return response.ok ? null : { $api_error: "unknown", response }
		}
		let bytes: Uint8Array | Error
		if ("bytes" in response) {
			// @ts-ignore
			bytes = await response.bytes().catch(Function.NOOP_ERR)
		}
		else bytes = await response.arrayBuffer().then(buf => new Uint8Array(buf)).catch(Function.NOOP_ERR)

		if (bytes instanceof Error) {
			gtrace.warn(bytes)
			return { $api_error: "parse", response, inner: bytes }
		}

		let value: R.ApiResponse | Error
		try { value = R.ApiResponse.decode(bytes) }
		catch (e) { value = e }

		if (value instanceof Error) {
			gtrace.warn(value)
			return { $api_error: "bad_response", response }
		}

		if (!response.ok) {
			if (value.$ === "error")
				return { $api_error: "remote", remote_error: value.$0 }

			return { $api_error: "unknown", response }
		}

		return value as TResponseContainer
	}

	return {
		req,
		headers,
	}
}()
