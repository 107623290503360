import { createMemo, For, onMount, Show } from "solid-js"
import { Transition } from "solid-transition-group"

import { ROUTES, useRouter } from "#/lib/navigation/mod"
import { useAuth } from "#/lib/auth"
import { langs } from "#/lib/appearance/i18n"
import { env, Platform } from "#/lib/behaviour"
import { UserAvatar } from "#/components/avatar"

export let MOBILE_NAVBAR_HEIGHT = "calc(3rem + env(safe-area-inset-bottom,0))"

export function MobileBottomNavbar() {
	let auth = useAuth()

	let isActive = (path: string) => router.top?.pathname === path
	let router = useRouter()

	let t = langs({
		ru: {
			[ROUTES.community.template]: "Сообщество",
			[ROUTES.projects.template]: "Проекты",
			[ROUTES.deals.template]: "Дела",
			[ROUTES.chats.template]: "Сообщения",
			[ROUTES.profile.resolve(auth.user.id)]: "Профиль",
		},
		en: {
			[ROUTES.community.template]: "Community",
			[ROUTES.projects.template]: "Projects",
			[ROUTES.deals.template]: "Deals",
			[ROUTES.chats.template]: "Messages",
			[ROUTES.profile.resolve(auth.user.id)]: "Profile",
		},
	})

	onMount(() => {
		if (env.rt.platform === Platform.IOS) {
			window.scrollTo(0, 0)
		}
	})

	return (
		<div
			class=":c: fixed bottom-0 left-0 right-0 z-100 box-content max-w-inherit flex select-none justify-evenly p-bottom-safe dark:bg-gray-900 light:bg-white-000"
			style={{ height: "3rem", }}
		>
			<For each={[
				[
					ROUTES.deals.template,
					p => <i {...p} classList={{ "i-hero:rectangle-stack": true, ...p.classList }} />,
				],
				[
					ROUTES.community.template,
					p => <i {...p} classList={{ "i-hero:user-group": true, ...p.classList }} />,
				],
				[
					ROUTES.projects.template,
					p => <i {...p} classList={{ "i-hero:squares-plus": true, ...p.classList }} />,
				],
				[
					ROUTES.chats.template,
					// dprint-ignore
					p => <i {...p} classList={{ "i-hero:chat-bubble-oval-left-ellipsis": true, ...p.classList }} />,
				],
				[
					ROUTES.profile.resolve(auth.user.id),
					p => (
						<UserAvatar
							user={auth.user}
							classList={{
								":c: uno-layer-v2:(outline-(2px solid blue-500))": p.active,
								...p.classList,
							}}
						/>
					),
				],
			] as const}
			>
				{([path, Comp]) => {
					let active = createMemo(() => isActive(path))
					return (
						<div
							class=":c: flex flex-basis-20% flex-col items-center justify-evenly"
							onClick={() => router.switchTo({ preserve: true, path })}
						>
							<Comp
								active={active()}
								classList={{
									":c: size-7 dark:c-gray-600 light:c-gray-300": true,
									"c-blue-500!": active(),
								}}
							/>
							<span
								class=":c: text-2.5 font-500 dark:c-gray-600 light:c-gray-300"
								classList={{ "c-blue-500!": active() }}
								innerText={t()[path]}
							/>
						</div>
					)
				}}
			</For>
		</div>
	)
}

let MOBILE_NAVBAR_FORBIDDEN_ROUTES = [
	ROUTES.login,
	ROUTES.onboarding,
	ROUTES.chat,
	ROUTES.deal_edit,
	ROUTES.post,
	ROUTES.index,
	ROUTES.all,
] as const

export let useIsMobileNavbarAvailable = (view = () => useRouter().view) => {
	return createMemo(() => view() && !MOBILE_NAVBAR_FORBIDDEN_ROUTES.includes(view().placeholder.route))
}

export function MobileNavbarPlaceholder() {
	let router = useRouter()
	let auth = useAuth()
	let isNavbarAvailable = useIsMobileNavbarAvailable(() => router.top)

	const enter_exit_active_class = ":c: transition-(duration-250 property-[bottom,opacity] ease-[cubic-bezier(0.2,0.75,0.15,0.98)])"
	const enter_exit_class = ":c: opacity-0 bottom--12!"
	return (
		<Show when={auth.user && auth.is_authenticated}>
			<Transition
				enterActiveClass={enter_exit_active_class}
				exitActiveClass={enter_exit_active_class}
				enterClass={enter_exit_class}
				exitToClass={enter_exit_class}
			>
				<Show when={isNavbarAvailable()}>
					<MobileBottomNavbar />
				</Show>
			</Transition>
		</Show>
	)
}
