// tailwindshades.com

export let COLORS = {
	"white-000": "#ffffff",
	"black-999": "#000000",

	"gray-000": "#fafafa",
	"gray-100": "#f0f0f2",
	"gray-200": "#cbced5",
	"gray-300": "#a0a3a6",
	"gray-400": "#657078",
	"gray-600": "#465057",
	"gray-700": "#3b3f49",
	"gray-800": "#24252c",
	"gray-850": "#1D1F22",
	"gray-900": "#17181a",
	"gray-950": "#070707",

	"red-100": "#F8C8CB",
	"red-200": "#F4ADB1",
	"red-300": "#F19298",
	"red-400": "#ED777E",
	"red-500": "#EA5C64",
	"red-600": "#E63C46",
	"red-700": "#E21D28",
	"red-800": "#C21923",
	"red-900": "#A2151D",

	"yellow-100": '#FFFD96',
	"yellow-200": '#FFFD7C',
	"yellow-300": '#FFFD63',
	"yellow-400": '#FFFC49',
	"yellow-500": '#FFFC30',
	"yellow-600": '#FFFC17',
	"yellow-700": '#FCF800',
	"yellow-800": '#E3DF00',
	"yellow-900": '#C9C600',

	"orange-100": "#FED3C3",
	"orange-200": "#FDBAA0",
	"orange-300": "#FCA07D",
	"orange-400": "#FC875A",
	"orange-500": "#FB6D37",
	"orange-600": "#FB5B1E",
	"orange-700": "#FA4905",
	"orange-800": "#E14104",
	"orange-900": "#C83A04",

	"blue-100": '#6896DD',
	"blue-200": '#477FD5',
	"blue-300": '#2D69C6',
	"blue-400": '#2658A5',
	"blue-500": '#1E4684',
	"blue-600": '#193B6F',
	"blue-700": '#15305A',
	"blue-800": '#102546',
	"blue-900": '#0B1A31',

	"green-100": "#E3F8E0",
	"green-200": "#C6F0BE",
	"green-300": "#A9E99D",
	"green-400": "#81DF6F",
	"green-500": "#59D441",
	"green-600": "#3FB529",
	"green-700": "#2F871E",
	"green-800": "#1F5914",
	"green-900": "#0F2C0A",

	"pink-100": "#FDE5EA",
	"pink-200": "#F0B8C6",
	"pink-300": "#F898B6",
	"pink-400": "#F672A2",
	"pink-500": "#F33D88",
	"pink-600": "#E90F74",
	"pink-700": "#B40C62",
	"pink-800": "#7F084B",
	"pink-900": "#4B0530",
} as const
