import { batch, createContext, createMemo, onCleanup, useContext, type JSX, type ParentProps } from "solid-js"
import { createMutable } from "solid-js/store"
import { EventEmitter } from "eventemitter3"
import { toast } from "solid-toast"

import {
	api, errorHandled, isApiError, LocalStorageKeys, rpc, signal, useCache,
	type R
} from "#/lib/mod"
import { tracing } from "./tracing"

type AuthState = {
	_: "stale"
} | {
	_: "authenticating"
} | {
	_: "authenticated"
	user_id: number
} | {
	_: "switching_account"
	user_id: number
}

export function AuthContextProvider(props: ParentProps) {
	let trace = tracing("AuthContextProvider")

	let cache = useCache()
	let store = createMutable({
		state: { _: "stale" } as AuthState,
		jwt: localStorage.getItem(LocalStorageKeys.JWT),
	})

	let emitter = new EventEmitter<{ login: []; logout: [] }>()

	if (store.jwt != null && !tokenExpired(store.jwt)) {
		authenticate(store.jwt)
	}

	function tokenExpired(token: string) {
		return Date.now() >= JSON.parse(atob(token.split(".")[1])).exp * 1000
	}

	function tryAuthenticate(jwt: string) {
		if (store.state._ === "authenticating") {
			trace.debug("Skipping tryAuthenticate, already authenticating")
			return
		}
		return authenticate(jwt)
	}

	async function authenticate(jwt: string) {
		trace.debug("Authenticating")

		store.state = { _: "authenticating" }
		api.headers["authorization"] = `Bearer ${store.jwt = jwt}`

		let response = await api.req("who_am_i")

		let isNetworkError = () => {
			return isApiError(response) && response.$api_error === "network"
		}

		let a = 1

		while (a <= 4 && isNetworkError()) {
			let timeout_ms = 2 ** a * 800 + Math.random() * 200

			response = await api.req("who_am_i")

			let CountDown = () => (
				<ErrorCountdown
					from_ms={timeout_ms}
					Component={(props) => (
						<div
							children={[
								`Ошибка сети при аутентификации.\n`,
								`Подключение заново через ${props.countdown} с.\n`,
								`Попытка: ${a}.`,
							]}
						/>
					)}
				/>
			)
			toast.error(CountDown, {
				style: { background: "orange" },
				duration: timeout_ms - 100,
			})
			await Promise.delay(timeout_ms)
			a++
		}

		if (isNetworkError()) {
			toast.error("Не удалось подключиться к серверу, перезагрузите приложение")
			store.state = { _: "stale" }
			return
		}

		if (errorHandled(response)) {
			logout()
			return
		}

		let { $0: whoami } = response

		if (!whoami) {
			trace.warn("Invalid jwt")
			logout()
			return
		}

		trace.debug('Loaded user', whoami)

		batch(() => {
			cache.update("users", [whoami])
			store.state = { _: "authenticated", user_id: whoami.id }
		})

		localStorage.setItem(LocalStorageKeys.JWT, jwt)

		emitter.emit("login")
	}

	async function logout() {
		// after changing user to getter from cache, it started to fail after logout
		// so I make sure components unmounted first and then we removing user.
		// Unfortunately, I'm not sure all timings in this Solid app.
		store.state = { _: "stale" }


		delete api.headers["authorization"]
		if (localStorage.getItem(LocalStorageKeys.JWT)) {
			localStorage.removeItem(LocalStorageKeys.JWT)
			toast.success("You are not authenticated anymore")
		}
		emitter.emit("logout")
	}

	async function refetchUser() {
		let response = await api.req("who_am_i")

		if (errorHandled(response, "Ошибка загрузки информации о текущем пользователе")) {
			return
		}

		store.state = { _: "authenticated", user_id: response.$0.id }
		cache.update("users", [response.$0])
		return response
	}

	async function updateUser(fields: R.UpdateUser) {
		let response = await rpc.request({
			$: "update_user",
			$0: fields
		})
		if (errorHandled(response, "Ошибка при сохранении данных пользователя")) {
			return response
		}
		let { $0: user } = response
		cache.update("users", [user])
		return user
	}

	// emitter.on("logout", cache.drop)
	// onCleanup(() => emitter.off("logout", cache.drop))

	let userMemo = createMemo(() => {
		if (store.state._ !== "authenticated" && store.state._ !== "switching_account")
			return null
		return cache.resolve("users", store.state.user_id)
	})

	let ctx = {
		store,
		tryAuthenticate,
		logout,
		refetchUser,
		updateUser,
		emitter,
		get jwt() {
			return store.jwt
		},
		get user() {
			return userMemo()
		},
		get is_auth_pending() {
			return store.state._ === "authenticating"
		},
		get is_authenticated() {
			return (store.state._ === "authenticated" || store.state._ === "switching_account") && ctx.user != null
		}
	}

	return Object.assign(<AuthContext.Provider value={ctx} children={props.children} />, { ctx })
}

let AuthContext = createContext<ReturnType<typeof AuthContextProvider>["ctx"]>()
export let useAuth = () => useContext(AuthContext)

type ErrorCountdownProps = {
	from_ms: number
	Component?(props: { countdown: number }): JSX.Element
}

export function ErrorCountdown(props: ErrorCountdownProps) {
	let seconds = Math.floor(props.from_ms / 1000)
	let sc = signal(seconds)

	let timeout: number
	function countdown() {
		timeout = window.setTimeout(() => {
			let current = sc() - 1
			if (current === 0) {
				clearTimeout(timeout)
				return
			}
			sc(current)
			countdown()
		}, 1000 - new Date().getMilliseconds())
	}

	countdown()

	onCleanup(() => clearTimeout(timeout))

	return <props.Component countdown={sc()} />
}
